import { Project } from '@remento/types/project';
import cronParser from 'cron-parser';
import dayjs from 'dayjs';

export function getProjectCron(project: Project) {
  const giftDate = project.startsOn;
  const cron = cronParser.parseExpression(project.notifications.interval, {
    currentDate: Math.max(Date.now(), giftDate),
    utc: true,
  });

  // If the series start date is the same as the first prompt date,
  // the first prompt should be sent at the next date.
  const nextDate = dayjs(cron.next().toDate());
  if (nextDate.isSame(giftDate, 'day') === false) {
    cron.reset();
  }

  return cron;
}
